import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@theme-ui/components';

import { getButtonStyles } from '@app/components/Button';
import GenericError, {
  GenericErrorMessage,
  GenericErrorTitle,
} from '@app/components/GenericError/GenericError';
import type { AppTheme } from '@app/styles/theme';
import { APP_BASE_URL } from '@config/common.config';

import Status from '../Status';
import messages from './NotFound.messages';

const NotFound = ({
  errorMessage,
}: {
  /**
   * This Error Message is only used internally for debugging and is printed to the console.
   */
  errorMessage?: string;
}) => {
  useEffect(() => {
    if (errorMessage) {
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }
  }, [errorMessage]);

  return (
    <Status code={404}>
      <Box my={[4, 5]} px={[3, 0]} data-testid="not-found">
        <GenericError showSupport={false}>
          <GenericErrorTitle>
            <FormattedMessage {...messages.headline} />
          </GenericErrorTitle>
          <GenericErrorMessage>
            <FormattedMessage {...messages.message} />
          </GenericErrorMessage>
          <a
            href={APP_BASE_URL}
            css={(theme: AppTheme) =>
              getButtonStyles({ type: 'primary', theme })
            }
          >
            <FormattedMessage {...messages.homepage} />
          </a>
        </GenericError>
      </Box>
    </Status>
  );
};

export default NotFound;
