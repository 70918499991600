import type { Magento2_RestApi_Product } from '@app/__graphql__/types';

import type { ProductFragmentFragment } from '../__graphql__/GetProductsForFinder';
import type { GetProductsBySkuQuery } from '../FeaturedProducts/__graphql__/getProductsBySku.query';
import { Translations360Tile } from './translations';

export type ProductsABTile =
  | ProductFragmentFragment[]
  | GetProductsBySkuQuery['getProducts']['result']['items'];

// checkProducts function checks if the first three products are Komplete 14 Standard, Ultimate or Collector's Edition
export const checkProducts = (products: ProductsABTile) => {
  const firstThreeProducts = products.slice(0, 3);
  const thirdProduct = firstThreeProducts[2];
  const id = thirdProduct?.id;

  return (
    id === 'Komplete15CollectorsEdition' ||
    id === 'Komplete15Standard' ||
    id === 'Komplete15Ultimate'
  );
};

export const TITLE_360 = '360';

const Tile360Data = (
  lang: string,
  className: string,
): ProductFragmentFragment | Magento2_RestApi_Product => ({
  __typename: 'FinderProduct',
  id: '360',
  title: '360',
  sku: '360-sku',
  image: 'https://storage.googleapis.com/ni-assets/temp/360-thumb.png',
  quickAddToCart: false,
  quickAddToCartSku: null,
  attributes: [
    {
      key: 'abTestClassName',
      value: className,
    },
    {
      key: 'description',
      value: Translations360Tile?.[lang].description,
    },
    {
      key: 'detail_page',
      value: `https://www.native-instruments.com/${lang}/products/subscription/360-subscription`,
    },
    {
      key: 'image_lazy',
      value: 'https://storage.googleapis.com/ni-assets/temp/360-thumb.png',
    },
    {
      key: 'override_price',
      value: true,
    },
    {
      key: 'custom_price_text',
      value: Translations360Tile?.[lang].cto,
    },
  ],
});

const validUrls = [
  {
    path: '/catalog/',
    trackingClass: 'abtest-360-tile abtest-360-catalog-page',
  },
  {
    path: '/catalog/new/',
    trackingClass: 'abtest-360-tile abtest-360-new-page',
  },
  {
    path: '/catalog/software/',
    trackingClass: 'abtest-360-tile abtest-360-software-page',
  },
  {
    path: '/catalog/software/bundles/',
    trackingClass: 'abtest-360-tile abtest-360-bundles-page',
  },
];

function removeLanguageFromPathname(pathname) {
  return pathname
    .split('/')
    .filter((segment, index) => index !== 1 || segment.length !== 2)
    .join('/');
}

export const shouldApplyABtest = (pathname, lang) => {
  const validLanguages = ['en', 'de', 'fr', 'es', 'ja', 'zh'];

  const checkRegex = validUrls.map(({ path }) => new RegExp(path));

  return (
    checkRegex.some((regex) => regex.test(pathname)) &&
    validLanguages.includes(lang)
  );
};

export const productListWith360Tile = (
  products: ProductsABTile,
  lang,
  path: string,
) => {
  const hasKomplete14 = checkProducts(products);
  const hasTraktor4 = products[1]?.sku === 'TraktorPro4';

  const pathname = removeLanguageFromPathname(path);

  const trackingClass =
    validUrls.find(({ path }) => path === pathname)?.trackingClass ?? '';

  const new360Tile = Tile360Data(lang, trackingClass);

  if (hasTraktor4) {
    return [new360Tile, ...products];
  }

  if (hasKomplete14) {
    return [...products.slice(0, 3), new360Tile, ...products.slice(3)];
  }

  return products;
};
